import { Subject } from 'rxjs';

import { IBitfUiMessages, IBitfBrowser } from '@interfaces';
import { detectBrowser } from '@bitf/utils/bitf-browser.utils';
import { IBitfStoreParts } from '../api-call-state/bitf-api-call-state.interfaces';

/**
 * The store model, used by Bitf core services
 */
export abstract class BitfStore {
  activeBreakpoints = {
    isXSmall: false,
    isSmall: false,
    isMedium: false,
    isLarge: false,
    isXLarge: false,
    isHandset: false,
    isTablet: false,
    isWeb: false,
    isHandsetPortrait: false,
    isTabletPortrait: false,
    isWebPortrait: false,
    isHandsetLandscape: false,
    isTabletLandscape: false,
    isWebLandscape: false,
    isHandsetLayout: false,
    isTabletLayout: false,
    isWebLayout: false,
  };
  // NOTE: will be true if the app is lounched in standalone mode from PWA
  isStandAlone = false;

  uiMessages$ = new Subject<IBitfUiMessages>();

  apiCallState: { [key: string]: IBitfStoreParts } = {};

  constructor(storeData: any = {}) {
    Object.assign(this, storeData);
  }

  get browser(): IBitfBrowser {
    return detectBrowser();
  }

  set browser(data: IBitfBrowser) {}

  toJSON() {
    const copy = Object.assign({}, this);
    delete copy.activeBreakpoints;
    delete copy.uiMessages$;

    return copy;
  }
}
