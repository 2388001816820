import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

import { IBitfOkCancelDialogData, IBitfCloseEvent } from '@interfaces';
import { EBitfCloseEventStatus } from '@enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bitf-mat-ok-cancel-dialog',
  templateUrl: './bitf-mat-ok-cancel-dialog.component.html',
  styleUrls: ['./bitf-mat-ok-cancel-dialog.component.scss'],
})
export class BitfMatOkCancelDialogComponent implements OnInit {
  form: UntypedFormGroup;
  okButtonType: ThemePalette;

  constructor(
    public dialogRef: MatDialogRef<BitfMatOkCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IBitfOkCancelDialogData,
    private formBuilder: UntypedFormBuilder
  ) {
    if (dialogData.prompt) {
      this.form = this.formBuilder.group({
        value: [dialogData.prompt.value || '', dialogData.prompt.isRequired ? Validators.required : ''],
      });
    }
  }

  ngOnInit() {
    switch (this.dialogData.okButtonType) {
      // NOTE all possible material values are primary, accent, or warn
      case 'PRIMARY':
        this.okButtonType = 'primary';
        break;
      case 'SECONDARY':
        this.okButtonType = 'accent';
        break;
      case 'WARNING':
        this.okButtonType = 'warn';
        break;
      case 'DANGER':
        this.okButtonType = 'warn';
        break;
      default:
        this.okButtonType = 'accent';
    }
  }

  onOk() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
      data: this.form && this.form.value,
    } as IBitfCloseEvent<void>);
  }

  onCancel() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }
}
