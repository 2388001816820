import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';

import { BitfMetadataService } from '@bitf/services/metadata/bitf-metadata.service';
import { BitfDynamicLocaleService } from '@bitf/services/locale/bitf-dynamic-locale.service';
import { environment } from '@env/environment';
import { APP_VERSION } from '@env/version';
import { Store } from '@models';
import { eStoreActions } from '@enums';

import {
  UiMessagesListenerService,
  ApiCallStateService,
  StorageService,
  StoreService,
  LoaderService,
} from '@services';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { BitfTryCatch } from '../../decorators/bitf-try-catch.decorator';

@Injectable({
  providedIn: 'root',
})
export abstract class BitfAppSessionService {
  protected translate: TranslateService;
  protected bitfMetadataService: BitfMetadataService;
  protected loaderService: LoaderService;
  protected uiMessagesListenerService: UiMessagesListenerService;
  protected apiCallStateService: ApiCallStateService;
  protected storageService: StorageService;
  protected storeService: StoreService;
  protected bitfDynamicLocaleService: BitfDynamicLocaleService;
  protected router: Router;
  protected breakpointObserver: BreakpointObserver;
  private loaderShownForRoute = new Map<string, boolean>();

  public readyState = {
    localeReady: false,
    platformReady: false,
    currencyLocaleReady: false,
  };

  constructor(protected injector: Injector) {}

  @BitfTryCatch()
  async init(): Promise<unknown> {
    this.translate = this.injector.get(TranslateService);
    this.storeService = this.injector.get(StoreService);
    this.loaderService = this.injector.get(LoaderService);
    this.router = this.injector.get(Router);
    this.breakpointObserver = this.injector.get(BreakpointObserver);

    /* NOTE:
    The StorageService initialize in the constructor because access is gained from dynamic local service
     */
    this.storageService = this.injector.get(StorageService);

    this.bitfDynamicLocaleService = this.injector.get(BitfDynamicLocaleService);
    const localeP = this.bitfDynamicLocaleService.init().then(() => {
      this.readyState.localeReady = true;
      this.bitfMetadataService = this.injector.get(BitfMetadataService);
      this.bitfMetadataService.init();
    });

    this.uiMessagesListenerService = this.injector.get(UiMessagesListenerService);
    this.uiMessagesListenerService.init();

    this.apiCallStateService = this.injector.get(ApiCallStateService);
    this.apiCallStateService.init();

    this.initMobileFixes();

    this.initLogSender();

    // this.initShowLoaderOnRouteChange();

    if (!environment.production) {
      console.log('ENV', environment);
    }

    window['printEnv'] = () => {
      console.log('Environment: ', environment);
      console.log('App Version: ', APP_VERSION);
      console.table(Breakpoints);
      console.table(this.storeService.store.activeBreakpoints);
    };

    return Promise.all([localeP]);
  }

  initMobileFixes() {
    const setVhValue = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVhValue();

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(100),
        tap(() => setVhValue())
      )
      .subscribe();
  }

  initBreakpointListener() {
    // Ref: https://material.io/design/layout/responsive-layout-grid.html#breakpoints
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Web,
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
        Breakpoints.WebPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletLandscape,
        Breakpoints.WebLandscape,
      ])
      .subscribe(() => {
        this.storeService.setStore((store: Store) => {
          store.activeBreakpoints.isXSmall = this.breakpointObserver.isMatched(Breakpoints.XSmall);
          store.activeBreakpoints.isSmall = this.breakpointObserver.isMatched(Breakpoints.Small);
          store.activeBreakpoints.isMedium = this.breakpointObserver.isMatched(Breakpoints.Medium);
          store.activeBreakpoints.isLarge = this.breakpointObserver.isMatched(Breakpoints.Large);
          store.activeBreakpoints.isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge);
          store.activeBreakpoints.isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);
          store.activeBreakpoints.isTablet = this.breakpointObserver.isMatched(Breakpoints.Tablet);
          store.activeBreakpoints.isWeb = this.breakpointObserver.isMatched(Breakpoints.Web);
          store.activeBreakpoints.isHandsetPortrait = this.breakpointObserver.isMatched(
            Breakpoints.HandsetPortrait
          );
          store.activeBreakpoints.isTabletPortrait = this.breakpointObserver.isMatched(
            Breakpoints.TabletPortrait
          );
          store.activeBreakpoints.isWebPortrait = this.breakpointObserver.isMatched(Breakpoints.WebPortrait);
          store.activeBreakpoints.isHandsetLandscape = this.breakpointObserver.isMatched(
            Breakpoints.HandsetLandscape
          );
          store.activeBreakpoints.isTabletLandscape = this.breakpointObserver.isMatched(
            Breakpoints.TabletLandscape
          );
          store.activeBreakpoints.isWebLandscape = this.breakpointObserver.isMatched(
            Breakpoints.WebLandscape
          );

          this.setLayoutBreakpoints(store);
        }, eStoreActions.BREAKPOINT);
      });
  }

  protected setLayoutBreakpoints(store) {
    if (store.activeBreakpoints.isHandsetPortrait || store.activeBreakpoints.isTabletPortrait) {
      store.activeBreakpoints.isHandsetLayout = true;
      store.activeBreakpoints.isTabletLayout = false;
      store.activeBreakpoints.isWebLayout = false;
    } else if (
      store.activeBreakpoints.isHandsetLandscape ||
      store.activeBreakpoints.isTabletLandscape ||
      store.activeBreakpoints.isWeb
    ) {
      store.activeBreakpoints.isHandsetLayout = false;
      store.activeBreakpoints.isTabletLayout = false;
      store.activeBreakpoints.isWebLayout = true;
    }
  }

  // initShowLoaderOnRouteChange() {
  //   this.router.events.subscribe((event: RouterEvent): void => {
  //     if (event instanceof RouteConfigLoadStart) {
  //       if (!this.loaderShownForRoute.has(event.route.path)) {
  //         this.loaderShownForRoute.set(event.route.path, true);
  //         this.loaderService.show();
  //       }
  //     } else if (event instanceof RouteConfigLoadEnd) {
  //       if (this.loaderShownForRoute.has(event.route.path)) {
  //         this.loaderShownForRoute.delete(event.route.path);
  //       }
  //       this.loaderService.hide();
  //     }
  //   });
  // }

  abstract initLogSender();
}
